import React from "react"

import IconRecurring from "../assets/icons/master/icon-recurring.svg"
import IconOnce from "../assets/icons/wild/cart-icon.svg"
import { isSubCasted } from "./productloader"

/*
 * @param  {Integer} num  An integer to create a percentage.
 * @return {Integer}       0 or 1 based on calculation
 */
var randomizer = function (num) {
  return Math.random() > num ? 0 : 1
}

var getOffset = function (el) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

/*
 * fox for Adaptive height slides with expanding content.
 * see https://github.com/kenwheeler/slick/issues/1320
 */
var sliderAdaptiveHeightFix = function ($slickSlider) {
  try {
    if (document.querySelector(".slick-list")) {
      document.querySelector(".slick-list").style.height = "auto"
    }
  } catch (error) {
    console.error("sliderAdaptiveHeightFix failed ", error)
  }
}

/*
 * Calculate the direction of arrow being clicked based on previous Index, Index, and Slide Count
 * Purpose to send arrow direction clicked from carousel to segment events
 */
const getArrowDirectionClicked = (prevIdx, idx, slideCount) => {
  if (prevIdx < idx || (prevIdx === slideCount - 1 && idx === 0)) {
    return prevIdx === 0 && idx === slideCount - 1 ? "left" : "right"
  } else {
    return "left"
  }
}

/*
 * @param  {Array} widths  An array of widths to replicate reflexbox width props functionality.
 * @return {Array}         An array to pass to reflexbox.
 */
var formatWidths = function (widths) {
  const inputWidths = widths.replace(/\s/g, "").split(",")
  const widthsArr = inputWidths.map((w) => {
    if (w === "null") {
      return null
    } else if (w.indexOf("/") > -1) {
      const split = w.split("/")
      return parseInt(split[0], 10) / parseInt(split[1], 10)
    }
    return 1
  })
  return widthsArr
}

/*
 * @param  {string} start  String of date YYYY-MM-DD
 * @return {number}        Months (30 days) since date entered
 */
var calcGrowthTime = function (start) {
  // let start = "2019-04-01"
  const d = new Date()
  const today = [
    d.getFullYear(),
    (d.getMonth() + 1).toString().padStart(2, "0"),
    d.getDate().toString().padStart(2, "0"),
  ].join("-")
  const msPerMonth = 1000 * 60 * 60 * 24 * 30
  const utcStart = Date.UTC(
    start.split("-")[0],
    start.split("-")[1],
    start.split("-")[2]
  )
  const utcNow = Date.UTC(
    today.split("-")[0],
    today.split("-")[1],
    today.split("-")[2]
  )
  const growthTime = Math.floor((utcNow - utcStart) / msPerMonth)
  return growthTime <= 0 ? 1 : growthTime + 1
}

/*
 * @param  {string} raw digit of day or visual month name
 * @return {string}     formatted value for gql mutation
 */
var dateMapDay = function (raw) {
  var formatMap = {
    1: "01",
    2: "02",
    3: "03",
    4: "04",
    5: "05",
    6: "06",
    7: "07",
    8: "08",
    9: "09",
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
  }

  return formatMap[raw] ? formatMap[raw] : raw
}

/*
 * @param  {string} raw digit of month or visual month name
 * @return {string}     formatted value for gql mutation
 */
var dateMapMonth = function (raw, short) {
  var formatMap = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
  }
  if (short) {
    formatMap = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sept",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    }
  }
  return formatMap[raw] ? formatMap[raw] : raw
}

var getCurrentSubscriptionFromSessionStorage = function () {
  if (typeof window !== `undefined`) {
    let currentSubscription = sessionStorage.getItem("currentSubscription")
    if (currentSubscription) {
      return currentSubscription
    }
  }
  return null
}

var clearSubscriptionLocalStorage = () => {
  if (typeof window !== `undefined`) {
    localStorage.removeItem("currentSubscription")
  }
}

var clearSubscriptionSessionStorage = () => {
  if (typeof window !== `undefined`) {
    sessionStorage.removeItem("currentSubscription")
  }
}

var clearCartLocalStorage = () => {
  if (typeof window !== `undefined`) {
    localStorage.removeItem("cart")
  }
}

/*
 * @param  {string} name of product in magento admin
 * @return {string} formatted name we want to display on UI
 */
var formatName = (name) => {
  return name && name.replace
    ? name
        .replace(/\\/g, "")
        .replace("Adaptogen", "Adapt")
        .replace("Adapt", "Adaptogen")
        .replace("One-Time Purchase", "")
        .replace(" Monthly", "")
        .replace(" Subscription", "")
        .replace(" Sub", "")
    : ""
}

var formatStatus = (status) => {
  return status.replace("payment_failed", "Payment failed")
}

const neutralCustomizeData = function (obj, updates) {
  let newObj = { ...obj }

  function eachRecursive(newObj, update) {
    for (let k in newObj) {
      if (typeof newObj[k] == "object" && newObj[k] !== null) {
        eachRecursive(newObj[k])
      } else {
        if (k === update.key) {
          newObj[k] = update.newValue
        }
      }
    }
    return newObj
  }

  updates.forEach((update) => {
    newObj = eachRecursive(newObj, update)
  })
  return newObj
}

const neutralRenderComponents = function (components) {
  return components.map((obj) => {
    return <obj.comp data={obj.data} {...obj.custom} />
  })
}

/*
 * return boolean if there are 2
 * requires skus param and purchase source in url
 * skus=WOM1-S01,WOM6-S01&source=pdp
 */
const isMultipleSkuDpa = function (queryParams) {
  if (typeof window !== `undefined`) {
    let skus = queryParams.split("skus=")[1]
    skus = skus.split("&")[0]
    if (skus.split(",").length > 1) {
      return true
    }
    return false
  }
}

/*
 * return object with skus/purchase_source/code from DPA link
 * requires skus param and purchase source in url
 * code is optional
 * ex) skus=WOM1-S01,WOM1-N01&purchase_source=influencer_lp,pdp&code=vikitest50
 * return)
 * {
 *   "skus": "WOM1-S01,WOM1-N01",
 *   "purchase_source": "influencer_lp,pdp"
 *   "code": "vikitest50",
 * }
 *
 * if there are not an equal amount of skus and purchase sources,
 * we duplicate the last purchase source for the missing ones
 *
 */
const getPropertiesFromDpa = function (dpaLink) {
  if (typeof window !== `undefined`) {
    const body = {}
    const queryString = dpaLink
      .replace(`${process.env.GATSBY_MAGENTO_URL}dpa/add/tocart`, "")
      .replace(`${process.env.GATSBY_MAGENTO_URL}dpa/add/tocart/`, "")
    const urlParams = new URLSearchParams(queryString)
    let numProducts = 0
    let numPurchaseSources = 0
    if (urlParams.get("skus")) {
      const skus = urlParams.get("skus")
      numProducts = skus.split(",").length
      body.skus = skus
    }
    if (urlParams.get("code")) {
      const code = urlParams.get("code")
      body.code = code
    }
    if (urlParams.get("purchase_source")) {
      let purchaseSource = urlParams.get("purchase_source")
      if (purchaseSource && purchaseSource.split) {
        numPurchaseSources = purchaseSource.split(",").length
        if (numProducts > numPurchaseSources) {
          const lastPurchaseSource =
            purchaseSource.split(",") && purchaseSource.split(",").at
              ? purchaseSource.split(",").at(-1)
              : ""
          for (let i = numPurchaseSources; i < numProducts; i++) {
            purchaseSource += `,${lastPurchaseSource}`
          }
        }
      }
      body.purchase_source = purchaseSource
    }
    return body
  }
}

// get query parameter value from the url
const getUrlParameter = function (name) {
  if (typeof window !== `undefined`) {
    /* eslint-disable */
    name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
    const results = regex.exec(window.location.search)
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "))
    /* eslint-enable */
  }
  return ""
}

const getProdDeliveryParameters = function (products) {
  // convert balance to Balance etc
  const productInUrl =
    getUrlParameter("product") && getUrlParameter("product").length > 1
      ? getUrlParameter("product")[0].toUpperCase() +
        getUrlParameter("product").slice(1)
      : null
  const productPreset = products[productInUrl] ? productInUrl : null
  const deliveryInUrl = getUrlParameter("delivery")
    ? getUrlParameter("delivery")
    : null
  let deliveryPreset =
    productPreset && deliveryInUrl
      ? products[productPreset].options.findIndex(
          (el) => el.id === deliveryInUrl
        )
      : null
  deliveryPreset = deliveryPreset > -1 ? deliveryPreset : null
  return { productPreset, deliveryPreset }
}

const getProductsBySub = (subscription) => {
  const product_growth_plan_data = Object.values(
    JSON.parse(subscription.product_growth_plan_data)
  )?.[0]
  // fixing critical errors with legacy accounts
  const productsArray =
    !!product_growth_plan_data &&
    product_growth_plan_data?.child_products !== []
      ? Object.values(product_growth_plan_data.child_products)
      : []
  const products = productsArray.length
    ? productsArray
    : product_growth_plan_data
    ? [product_growth_plan_data]
    : []
  return products?.sort((a) => (a?.is_core ? -1 : 1))
}

const isAndroid = () =>
  typeof navigator !== "undefined" && /android/i.test(navigator.appVersion)

const androidScrollFix = (selector = "#Modal input[type='text']") => {
  try {
    if (isAndroid()) {
      document.querySelectorAll(selector).forEach((box) =>
        box.addEventListener("focus", (e) => {
          setTimeout(() => {
            box.scrollIntoView()
          }, 250)
        })
      )
    }
  } catch (e) {
    console.error(e)
  }
}

const cmsFontHelper = (text) => {
  return text && text.replace
    ? text
        .replace(/<em>/gm, "<span class='font-grilliItalic'>")
        .replace(/<\/em>/gm, "</span>")
        .replace(/<strong>/gm, "<span class='font-grilliBook font-bold'>")
        .replace(/<\/strong>/gm, "</span>")
        .replace(/<p>/gm, "")
        .replace(/<\/p>/gm, "")
    : text
}

const cmsFontHelperSup = (text) => {
  return text && text.replace
    ? text
        .replace(/\|/gm, "<br/><br/>")
        .replace(/<em>/gm, "<sup>")
        .replace(/<\/em>/gm, "</sup>")
    : text
}

const cmsArrayHelper = (text) => {
  return text?.split(",")
}

const cmsAltHelper = (text = "") => {
  const arr = text && text.split ? text.split("|") : ["", ""]
  return { altp: arr[0], title: arr[1] || arr[0] }
}

const getRecaptchaPublicKey = () => {
  const script = document.getElementById("grecaptchaV3")
  if (!!script) {
    const src = script.src
    const key = src.split("?render=")[1]
    if (key) {
      return key
    }
    return null
  }
  return null
}

const isIpad = () => {
  return (
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
    navigator.platform.match(/iPad/i)
  )
}

/**
 *
 * @param {string} url
 * @param {number} width
 * @returns {`${string}?width=300`|`${string}&width=300`|""}
 * see https://developer.fastly.com/reference/io/
 */
const getImgUrlWithSuffix = (url, width = 420) => {
  if (url && width) {
    if (url.includes("?")) {
      return `${url}&width=${width}`
    }
    return `${url}?width=${width}`
  }
  return ""
}

/**
 * Multipurpose caster by levdev
 *
 * cast 70.4 to 70.40
 * remove commas
 * show cents for non-round prices
 * @param price
 * @returns {`$price`}
 */
const castPrice = (price) => {
  if (typeof price !== "string") {
    price = "" + price
  }
  if (price) {
    const arr = price.replace(",", "").split(".")
    let cents = arr[1] && arr[1].length ? arr[1].slice(0, 2) : ""
    if (cents.length === 1) {
      cents = cents + "0"
    }
    const sfx = cents && cents !== "00" ? "." + cents : ""
    return `$${arr[0] + sfx}`
  }
  return `$${price}`
}

const isElementHidden = (el) => {
  return el?.offsetParent === null
}

const isProductPath = (pathname = "") => {
  return (
    pathname.indexOf("nutrafol-core") > -1 ||
    pathname.indexOf("women-balance") > -1 ||
    pathname.indexOf("nutrafol-postpartum") > -1 ||
    pathname.indexOf("womens-vegan") > -1
  )
}

const isProductPathMen = (pathname = "") => {
  return (
    pathname.indexOf("/men/products/hair") > -1 ||
    pathname.indexOf("/men/products/thinning") > -1 ||
    pathname.indexOf("/men-hair-growth-supplement/") > -1 ||
    pathname.indexOf("/golfdigest/") > -1
  )
}

const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect()

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  )
}

const isMobile =
  typeof document !== "undefined" && document.body.clientWidth < 768

const intervals = {
  0: {
    text: `One-Time Purchase`,
    icon: <IconOnce />,
  },
  1: {
    text: `Monthly Subscription`,
    icon: <IconRecurring />,
  },
  3: {
    text: `3-Month Subscription`,
    icon: <IconRecurring />,
  },
  6: {
    text: `6-Month Subscription`,
    icon: <IconRecurring />,
  },
}
// interval - 3 month / 6 month / 1 month
const getFreqByInterval = (interval) => {
  const countOfMonth = parseInt(interval?.[0], 10) || 0
  return [
    countOfMonth,
    intervals[countOfMonth].text,
    intervals[countOfMonth].icon,
  ]
}

const getFormattedDate = (date) => {
  const isoDateString = date?.replace?.(/ /, "T")
  const inputDate = new Date(isoDateString)
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    return "Invalid Date"
  }

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  return new Intl.DateTimeFormat("en-US", options).format(inputDate)
}

const addDays = (date, days) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
}

const pauseAllVideos = function () {
  const medias = [...document.querySelectorAll("video:not(.no-pause)")]
  medias.forEach(function (media) {
    //console.info('media paused')
    try {
      media?.pause()
    } catch (error) {
      //
    }
  })
}

/**
 *
 * @param {string} pathname
 * @param {{}} layoutData
 * @returns {*}
 */
const slugFound = (pathname, layoutData) =>
  layoutData.find((el) => el.url_slug === pathname)

/**
 *
 * @param  {{}}layoutData
 * @returns {boolean}
 */
const isNoIndexNoFollow = (layoutData) => layoutData?.no_index_no_follow

/**
 *
 * @param  {{}}layoutData
 * @returns {boolean}
 */
const noIndexDoFollow = []
const isNoIndexDoFollow = (slug) => noIndexDoFollow.includes(slug)

/**
 *
 * @param {{}} location
 * @param {{}} pageContext
 * @param {{}} layoutData
 * @returns {{}}
 */
const getLayoutDataBySlug = (location, pageContext, layoutData) => {
  const PRE_PROD = process.env.GATSBY_ACTIVE_ENV !== "production"
  const SUFFIX_STAGING = "_staging2"
  const pageContextLayout = pageContext?.layout

  let { pathname } = location
  let pathnameStaging = pathname?.slice(0, -1) + SUFFIX_STAGING + "/"

  if (pathname.indexOf("/sms") > -1) {
    pathname = "/sms/"
  }

  const reference_data_common = `<br/><br/>1. Ablon, G. J Drugs Dermat. 2018. 2. Stephens, T., et al. JCAD. 2022. 3. Ablon, G., et al. JDD, 2021. 4. Berkowitz, S., et al. ASDS. 2020. 5. Auddy, B HJ. JANA. 2008. 6. Beoy, L. Tropical Life Sci Res. 2010. 7. Proksch, E. Skin Pharm Physiology. 2013. 8. Meissner, H.O. IJBS. 2005. 9. Rossi, A. Int. J of Immuno and Pharm. 2012. 10. Pandely, A. Bio Med & Pharm. 2018. 11. Le Floc’h, C. JCD. 2014. 12. Tenore, G. J Med Food. 2016. 13. Jagetia, GC. J of Clinical Immunology. 2007. <span class="vegan">14. Nutrafol. Data on file. 2022. 15. Wandry, F. J Comm Med and Pub Health Rep. 2021.</span>`

  // all core_only influencers share same layout data
  if (pathname.includes("/influencer/") && !pathname.includes("/hairserum/")) {
    let reference_data = ` 
        *New customers only. Limit one per customer. Not combinable with other offers. Limited time. 3-Month delivery discount applied to first delivery only. Your subscription will renew every 90 days at $224.`

    /**
     * NT2-18994
     * Partners LP | Private Suites
     */
    if (pathname.includes("PSXNUTRAFOL")) {
      reference_data = ` 
        *New customers only. Limit one per customer. Not combinable with other offers. Limited time. Discount applied to first delivery only. Must be placed by 09/03/24 at 11:59pm PST. Your subscription will renew every 90 days at $224, or every 180 days for $528.`
    }
    return {
      footer_type: "Simple Footer",
      has_main_padding: true,
      has_ribbon: true,
      header_background_color: "White",
      header_type: "Really Simple Header",
      no_index_no_follow: true,
      reference_data: {
        reference_data: reference_data + reference_data_common,
      },
      url_slug: location,
    }
  }

  if (pageContextLayout === "core_brand") {
    return {
      footer_type: "Simple Footer",
      no_index_no_follow: true,
      has_main_padding: false,
      header_type: "Default",
      has_ribbon: true,
      reference_data: {
        reference_data: ` 
             *According to IQVIA ProVoice survey for 12 months ending March 31, 2023.<br/><br/>
              1. Ablon G. J Drugs Dermatol. 2018. 2. Stephens, T. JCAD. 2022./Nutrafol. Data on file. 2022. 3. Ablon G. J Drugs Dermatol. 2022. 4. Berkowitz S. ASDS. 2020./Nutrafol. Data on file. 2020. 5. Ablon G. J Drugs Dermatol. 2021. 6. Nutrafol. Data on file. 2022. 7. Chandrashekar BS. J Res Derm. 2020. 8. Grothe T. Phytotherapy Research. 2020. 9. Tenore G. J Med Food. 2016. 10. Nutrafol. Data on file. 2023. 11. Lactation Database. 2021. 12. Budzynska, K. Breastfeed Med. 2013.        `,
      },
      url_slug: location,
    }
  }

  if (pathname.startsWith("/tracking/")) {
    return {
      has_main_padding: true,
      has_ribbon: true,
      header_background_color: "White",
    }
  }

  // use staging layout data if found
  if (PRE_PROD && layoutData && slugFound(pathnameStaging, layoutData)) {
    return slugFound(pathnameStaging, layoutData)
  }

  return layoutData && slugFound(pathname, layoutData)
}

/*
 * define .playing as a custom property for all media elements and access it when needed.
 * document.querySelector('video').playing
 */
// eslint-disable-next-line no-prototype-builtins
if (
  typeof HTMLMediaElement !== "undefined" &&
  !HTMLMediaElement.prototype.hasOwnProperty("playing")
) {
  Object.defineProperty(HTMLMediaElement.prototype, "playing", {
    get: function () {
      return !!(
        this.readyState > 2 &&
        this.currentTime > 0 &&
        !this.paused &&
        !this.ended
      )
    },
  })
}

const USE_EVIDON = typeof window !== "undefined" && process.env.GATSBY_EVIDON_ID

const buildEvidon = () => {
  if (process.env.GATSBY_EVIDON_ID) {
    return (
      <div className={`evidon-notice-wrap hidden`}>
        <span className="evidon-notice-link hidden"></span>
      </div>
    )
  } else {
    return null
  }
}

/**
 *
 * @param {string} email
 * @returns {boolean}
 */
const isEmailValid = (email) => {
  return Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
}

/**
 *
 * @param {{}} product
 * @returns {[]}
 */
const filterChildProductsSortByIsCore = (product) => {
  let growthProducts = []
  Object.keys(product.child_products).forEach((prod) => {
    let childProduct = product.child_products[prod]
    if (!isSubCasted(childProduct)) {
      growthProducts.push(childProduct)
    }
  })
  growthProducts.sort((a, b) => (a.is_core < b.is_core ? 1 : -1))
  return growthProducts
}

const PRODMAP = {
  "WOM1-S01": "Hair Growth Nutraceutical for women",
  "WOMS1-S01": "De-Stress Hair Growth Duo",
  "WOMH1-S01": "Gut Microbiome Hair Growth Duo",
  "WOMI1-S01": "Strengthening Hair Growth Duo",
  "WOMM1-S01": "Hormone Support Hair Growth Duo",
  "WOMT1-S01": "Toxin Cleanse Hair Growth Duo",
  "WOMB1-S01": "Energy Hair Growth Duo",
  "BAL1-S01": "Women's Balance Hair Growth Nutraceutical",
  "BALS1-S01": "De-Stress Hair Growth Duo",
  "BALH1-S01": "Gut Microbiome Hair Growth Duo",
  "BALI1-S01": "Strengthening Hair Growth Duo",
  "BALM1-S01": "Hormone Support Hair Growth Duo",
  "BALT1-S01": "Toxin Cleanse Hair Growth Duo",
  "BALB1-S01": "Energy Hair Growth Duo",
  "VEG1-S01": "Women’s Vegan Hair Growth Nutraceutical",
  "VEGS1-S01": "De-Stress Hair Growth Duo",
  "VEGH1-S01": "Gut Microbiome Hair Growth Duo",
  "VEGI1-S01": "Strengthening Hair Growth Duo",
  "VEGM1-S01": "Hormone Support Hair Growth Duo",
  "VEGT1-S01": "Toxin Cleanse Hair Growth Duo",
  "VEGB1-S01": "Energy Hair Growth Duo",
  "POS1-S01": "Postpartum Hair Growth Nutraceutical",
  "RPURIFIER-N01": "Shampoo",
  "SDEFENDER-N01": "Conditioner",
  "GACTIVATOR-N01": "Hair Serum",
  "BBLOCKER-N01": "Scalp Mask",
  "SRELIEVER-N01": "Scalp Essence",
  "HBALANCEKIT-N01": "Scalp Travel Set",
  "WOMFHK3-S01": "Fullest Hair Kit",
  "MENPRO1-S01": "Hair Growth Nutraceutical for men",
  "MENDEF1-S01": "Hairline Defender",
  "MENFUL1-S01": "Full Support",
}

const formatPrice = (val) => (val % 1 > 0 ? val.toFixed(2) : val)

const smsCopy = `By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages from Nutrafol at the cell number used when signing up. Consent to receive automated marketing texts is not a condition of any purchase. Messages may be sent using an automatic telephone dialing system or other automated system for the selection or dialing of numbers. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. View <a href='https://attnl.tv/t/mSq' target='_blank' rel='noopener noreferrer' class='text-master-primary-default font-dinamoBold'>Terms</a> & <a href='https://attnl.tv/p/mSq' target='_blank' rel='noopener noreferrer' class='text-master-primary-default font-dinamoBold'>Privacy</a>.`

const replaceNextRun = (subscription) => {
  return subscription?.next_run?.replace(/ /g, "T")
}

/* classNames('container', { 'logged-container': true, 'test-false':false }) => 'container logged-container' */
const classNames = (...classNames) => {
  const classes = classNames
  .flatMap((className) => {
    if (typeof className === "string") {
      return className
    } else if (typeof className === "object") {
      return Object.keys(className)
      .filter((key) => className[key] && key)
      .join(" ")
    }
  })
  .join(" ")

  return classes
}

const getGender = (subscription) => {
  try {
    return Object.values(
      JSON.parse(subscription?.product_growth_plan_data)
    )?.[0]?.core_category
  } catch (e) {
    console.error("getGender error", e)
  }
}

const formatDisclaimer = (product) => {
  const sfx = product.includes("Postpartum")
    ? "saw significant hair growth."
    : "experienced significant hair growth."
  return (
    "In a 6-month clinical study, participants taking " +
    product
      .replace("Using", "")
      .replace("Taking", "")
      .replace("Hair Growth Nutraceutical", "") +
    sfx
  )
}

export {
  androidScrollFix,
  smsCopy,
  randomizer,
  getOffset,
  formatWidths,
  calcGrowthTime,
  dateMapDay,
  dateMapMonth,
  getFormattedDate,
  addDays,
  getCurrentSubscriptionFromSessionStorage,
  clearSubscriptionLocalStorage,
  clearSubscriptionSessionStorage,
  clearCartLocalStorage,
  formatName,
  formatStatus,
  sliderAdaptiveHeightFix,
  getArrowDirectionClicked,
  neutralCustomizeData,
  neutralRenderComponents,
  isMultipleSkuDpa,
  getLayoutDataBySlug,
  getPropertiesFromDpa,
  getUrlParameter,
  getProductsBySub,
  getProdDeliveryParameters,
  getRecaptchaPublicKey,
  cmsFontHelper,
  cmsFontHelperSup,
  cmsArrayHelper,
  cmsAltHelper,
  getImgUrlWithSuffix,
  isIpad,
  castPrice,
  isElementHidden,
  isProductPath,
  isProductPathMen,
  isAndroid,
  isElementInViewport,
  isMobile,
  isNoIndexNoFollow,
  isNoIndexDoFollow,
  pauseAllVideos,
  formatPrice,
  PRODMAP,
  isEmailValid,
  buildEvidon,
  USE_EVIDON,
  replaceNextRun,
  getFreqByInterval,
  filterChildProductsSortByIsCore,
  getGender,
  classNames,
  formatDisclaimer,
}
