import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

export const HEADER_HEIGHT_MOBILE = 96
export const HEADER_HEIGHT_DESKTOP = 72
export const tabletFold = 1024

export const AboveHeaderWrap = styled(Flex)`
  position: relative;
`

export const SiteSwitchHolder = styled(Box)`
  background: #fff;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    background: transparent;
    min-height: 35px;
  }
`

export const HeaderWrap = styled.header`
  background: transparent;

  border-radius: 2px;

  .mobile-nav-flex-variant {
    display: flex;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .right-wrap-account {
    @media (min-width: 1024px) {
      padding-right: 10px;
    }
  }

  &.nav-open {
    .wrap {
      position: relative;

      .toggle-nav {
        margin-top: 0;
        margin-left: 0;

        span {
          transform: rotate(45deg) !important;
          top: 1.5rem;
          width: 20px;

          &:before {
            background-color: transparent;
          }

          &:after {
            top: 0;
            transform: rotate(-90deg) !important;
          }
        }
      }
    }

    &.second-level-active {
      .back-wrapper {
        display: inline-block;
      }
    }
  }

  &[data-mousedown] {
    *:focus {
      //outline: none;
    }
  }

  @media (min-width: 1024px) {
    height: auto;
    //overflow: hidden;
    border: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    box-shadow:
      0px 1.75px 2.31px 0px #00000002,
      0px 8px 6.5px 0px #00000004,
      0px 20.25px 17.44px 0px #00000005,
      0px 40px 40px 0px #00000008;
  }
`

export const HeaderContainer = styled.div`
  position: relative;
  padding: 0 !important;
  background: #fff;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
`

export const ContentsContainer = styled.div`
  > div:not(.ribbon-container, .mobile-nav-flex-variant) {
    background: #fff;
  }
  @media (min-width: ${tabletFold}px) {
    .toggler-wrap {
      order: 1;
      width: 104px;
      padding: 0 0 0 2px;
    }

    .left-wrap {
      order: 3;
      width: calc(100% - 444px);
      align-self: center;
      padding-left: 16px;
      //border: 1px red dotted;
    }

    .center-wrap {
      order: 2;
      width: 125px;
      align-self: center;
      //border: 1px red dotted;
    }

    .right-wrap {
      padding-right: 8px;
      order: 4;
      width: 215px;
      align-self: center;
      //border: 1px red dotted;
    }
  }
  @media (min-width: 1024px) {
    .left-wrap {
      order: 2;
      width: calc(50% - 75px);
      padding-left: 0;
    }

    .center-wrap {
      order: 3;
      width: 150px;
    }

    .right-wrap {
      order: 4;
      width: calc(50% - 75px);
      padding-right: 24px;
    }
  }
  @media (min-width: 1280px) {
    .right-wrap {
    }
  }
`

export const BelowFoldContainer = styled(Flex)`
  height: inherit;
  background: #fff;
  @media (min-width: 1024px) {
    padding: 8px;
  }
  @media (min-width: 1024px) {
    padding: 0 8px;
  }
`

export const MobileAlt = styled.button`
  display: contents;
  text-align: center;
  height: 56px;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const NavMobile = styled(Flex)`
  background-color: #f8f8f5;
  height: 44px;
  //border: 1px ${theme.wild.color.nova.transparency.black[20]} solid;

  button.textLink {
  }

  .takequiz {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 16px;
    background: ${theme.wild.color.nova.transparency.black[60]};

    .textLink {
      color: ${theme.wild.color.nova.base.white} !important;
      background: none;
    }
  }
`

export const LowerWrapBox = styled(Box)`
  background: #fff;
  border-top: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  height: 40px;

  @media screen and (min-width: 1024px) {
    padding: 0;
    margin-top: 0;
    padding-left: 8px;
    align-items: flex-end;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 11px 15px 0px #00575214;
    box-shadow: 0px 9px 46px 0px #0057520f;
    box-shadow: 0px 24px 38px 0px #0057520f;
  }
`

export const LowerWrapContainer = styled(Flex)`
  justify-content: center;

  display: none;

  @media screen and (min-width: 500px) {
    max-width: 635px;
    margin: auto;
    height: 40px;
    display: flex;
    align-items: center;
  }

  &.non-account-path {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  &:not(.is-menu) {
    align-items: center;
    padding: 8px 9px;
    @media screen and (min-width: 1024px) {
      padding: 4px 8px 6px;
      align-items: flex-end;
    }
  }

  .simple-account {
    padding-bottom: 0;
    margin-right: 3px;
    @media (min-width: 1024px) {
      margin-right: 11px;
    }
  }

  .menu-item-wrap {
    text-align: center;
    position: relative;
    flex-grow: 1;
    white-space: nowrap;

    a > div {
      margin: auto;
      @media (min-width: 768px) and (max-width: 1023px) {
        height: 24px;
      }
    }

    &.logout {
      button {
        width: 100%;
        height: 100%;
        text-align: center;
        @media (max-width: 1023px) {
          transform: translateY(-2px);
        }

        .textelement {
          //transform: translateY(-1px);
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          background: ${theme.wild.color.nova.base.solid[20]};
        }
      }
    }

    .current {
      @media (min-width: 1024px) {
        //highlight current?
      }
    }
  }
`

export const LinkFlex = styled(Flex)`
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  height: 100%;
  box-sizing: border-box;

  @media (min-width: 500px) {
    min-width: unset;
    padding: 4px 8px;
  }

  @media (min-width: 1024px) {
    &:hover,
    &:focus,
    &:active {
      background: ${theme.wild.color.nova.base.solid[20]};
    }
  }
`

export const LowerWrapBoxMobile = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  @media (min-width: 500px) {
    display: none;
  }

  /* Fading effect on edges */

  &::before,
  &::after {
    background: transparent;
  }

  &.fade-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30px;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    z-index: 1;
    opacity: 0; /* Initially hidden */
    transition:
      background 1s ease,
      background-size 1s ease,
      opacity 1s ease;
  }

  &.fade-right::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30px;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    z-index: 1;
    opacity: 0; /* Initially hidden */
    transition:
      background 1s ease,
      background-size 1s ease,
      opacity 1s ease;
    transform: rotate(180deg); /* Mirror the gradient for the right edge */
  }

  /* Show fading effect only when needed */

  &.fade-left::before {
    opacity: 1;
  }

  &.fade-right::after {
    opacity: 1;
  }

  /* Scrollable navigation with smooth scrolling */

  .nav-scroll {
    margin-right: 20px;
    white-space: nowrap;
    overflow-x: auto;
    touch-action: pan-x;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none; /* For Firefox */

    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Edge */
    }
  }

  /* Individual nav items */

  .menu-item-wrap {
    width: auto;
    text-align: center;
    height: 40px;

    .nova {
      //min-width: 74px;
      width: max-content;
    }
    &.logout {
      //min-width: 74px;
      margin: 0 0 0 10px;
      button {
        height: 100%;
      }
    }

    .menu-item-link {
      //height: 100%;
    }
  }
`