import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"
import { Link } from "gatsby"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

const SiteSwitchWrap = styled(Flex)`
  flex-direction: row;
  background-color: #fff;
  min-height: 36px;
  align-items: center;

  @media (min-width: 1024px) {
    border-top-left-radius: 4px;
  }
`

const SiteSwitchBox = styled(Box)`
  text-align: center;
  height: 36px;
  border-bottom: 1px solid #fff;

  a,
  p {
    width: 100%;
    height: 100%;
    line-height: 36px;
  }

  &:first-of-type {
    &:hover {
      background-color: ${theme.wild.color.nova.base.solid[20]};
    }

    border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  }
`

const SiteSwitchLink = styled.a`
  text-align: center;
  height: 36px;
  border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  width: 33.34%;
  cursor: pointer;

  a,
  p {
    width: 100%;
    height: 100%;
    line-height: 36px;
  }

  &:hover {
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }
`

const SiteSwitch = ({ className = `` }) => {
  const onRedirect = (destination, text) => {
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination,
      text,
    })
  }

  return (
    <Box className={`show-lg-up`}>
      <SiteSwitchWrap className={className}>
        <SiteSwitchBox width={[1 / 3]}>
          <Link to={`/`} onClick={() => onRedirect(`/`, "Women")}>
            <TextElementNova
              text={`Women`}
              className={`CTA-10-Dia-Normal text-nova-transparency-black-70`}
            />
          </Link>
        </SiteSwitchBox>
        <SiteSwitchBox width={[1 / 3]}>
          <TextElementNova
            text={`Men`}
            className={`CTA-10-Dia-Normal text-nova-base-black`}
          />
        </SiteSwitchBox>
        <SiteSwitchLink
          width={[1 / 3]}
          href={`${process.env.GATSBY_MAGENTO_URL}skin/`}
          onClick={() => onRedirect(`skin/`, "Skin")}
        >
          <TextElementNova
            text={`Skin`}
            className={`CTA-10-Dia-Normal text-nova-transparency-black-70`}
          />
        </SiteSwitchLink>
      </SiteSwitchWrap>
    </Box>
  )
}

export default SiteSwitch
