import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { ThemeProvider } from "@emotion/react"
import { globalHistory, useLocation } from "@reach/router"
import Helmet from "react-helmet"
import { useQuery } from "react-apollo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Box } from "@rebass/grid/emotion"

import UserContext from "../../context/UserContext"
import { ACCOUNT_PAGES, SUBPUSH_PAGES } from "../../constants"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

import {
  getLayoutDataBySlug,
  isNoIndexNoFollow,
  isNoIndexDoFollow,
} from "../../utils/general"

import { readCookie, setCookie } from "../../utils/cookies"
import { isCustomerAuthorized } from "../../queries/login"
import { currentTheme } from "../../utils/theming"
import ExternalScripts from "../externalscripts"

import HeaderNova from "../wild/nova/header/header"
import HeaderNovaVariant from "../wild/nova/headervariant/header"
import HeaderWildMen from "../wild/men/header"
import HeaderMen from "../wild/men/headervariant"
import HeaderReallySimple from "../wild/master/header/headerreallysimple"
import HeaderReallySimpleVariant from "../wild/nova/headervariant/headerreallysimple"
import HeaderSimple from "../wild/master/header/headersimple"
import HeaderSimpleVariant from "../wild/nova/headervariant/headersimple"
import SessionMessages from "../session/sessionmessages"
import FooterNova from "../wild/nova/footer/footer"
import NovaFooterCart from "../wild/nova/footer/footer-cart"
import NovaFooterLP from "../wild/nova/footer/footer-lp"
import FooterWildMen from "../wild/men/footer"
import FooterSimpleWildMen from "../wild/men/footer/footersimple"
import Modal from "../account/modal"
import GenericModalContent from "../modal/genericmodalcontent"
import { useRewards } from "../../hooks/useRewards"

const acceptedCaptchaUrls = [
  "login",
  "create",
  "password",
  "profile",
  "subscriptions",
  "edit-address",
  "edit",
  "privacy-policy",
  "privacy-request",
]

gsap.registerPlugin(ScrollTrigger)

const Layout = ({ children, pageContext, path }) => {
  const siteData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulLayoutData {
        nodes {
          url_slug
          template_name
          has_main_padding
          has_ribbon
          no_index_no_follow
          header_type
          header_background_color
          footer_type
          template_name
          hide_attentive_popup
          reference_data {
            reference_data
          }
        }
      }
      allContentfulHeaderRibbon {
        nodes {
          showRibbon
          ribbonText {
            raw
          }
          ribbonLink
          site
        }
      }
    }
  `)

  const location = useLocation()
  const ribbon_info = siteData?.allContentfulHeaderRibbon?.nodes
  const ribbon_info_women = ribbon_info.find((el) => el.site === "women")
  const ribbon_info_men = ribbon_info.find((el) => el.site === "men")
  const USE_MEN_RIBBON = ribbon_info_men?.showRibbon

  /*
   * console.log("ribbon_info_women", ribbon_info_women)
   * console.log("ribbon_info_men", ribbon_info_men)
   */

  const isMenTheme = currentTheme(location) === "men"
  const isSpacerVisible = currentTheme(location) === "master" || USE_MEN_RIBBON
  const reformattedPathname =
    (location && location?.pathname.split("/").join("-")) || ""

  /**
   ** Prevent safari loading from cache when back button is clicked in cart
   * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
   * https://stackoverflow.com/questions/5297122/preventing-cache-on-back-button-in-safari-5
   */
  if (typeof window !== "undefined") {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
    window.addEventListener("unload", () => {}, false)
  }

  const layout_data_info = siteData?.allContentfulLayoutData?.nodes

  const layoutDataBySlug = getLayoutDataBySlug(
    location,
    pageContext,
    layout_data_info
  )
  const isHeaderTypeNone = layoutDataBySlug?.header_type === "None"
  const isHeaderTypeDefault = layoutDataBySlug?.header_type === "Default"
  const isHeaderReallySimple =
    layoutDataBySlug?.header_type === "Really Simple Header"
  const isHeaderSimple = layoutDataBySlug?.header_type === "Simple Header"
  const isAttentivePopupHidden = layoutDataBySlug?.hide_attentive_popup
  const referenceText = layoutDataBySlug?.reference_data?.reference_data
  const hasMainTopPadding = Boolean(layoutDataBySlug?.has_main_padding ?? true)
  const isRibbonSub = SUBPUSH_PAGES.includes(location?.pathname)
  const isRibbonVisible = Boolean(layoutDataBySlug?.has_ribbon)
  const isMenRibbon =
    USE_MEN_RIBBON &&
    isRibbonVisible &&
    ["/men/", "/redesign/components/headervariantmen_ribbon/"].includes(
      location?.pathname
    )
  const isRibbonReallySimple = location?.pathname === "/tt/promo/"

  const [modalVisible, toggleModalVisible] = useState(false)
  const [mageMessages, setMageMessages] = useState([])
  const [sessionRequestMade, setSessionRequestMade] = useState(false)
  const [authRequestMade, setAuthRequestMade] = useState(false)
  const [modalMessage, addModalMessage] = useState({})

  const userState = useContext(UserContext)
  //NT2-20279 Loyalty | Global Ribbon for myNutrafol Customers
  const { nextReward } = useRewards(userState)
  let PROMO_VARIANT = isRibbonSub ? "subpush" : "mayGwp"
  if (nextReward?.segment_name) {
    PROMO_VARIANT = nextReward?.segment_name
  }

  if (isAttentivePopupHidden && typeof window !== "undefined") {
    document.cookie = `hide_attentive_popup=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=${location?.pathname};`
  }

  /** mouseDown is used to remove outline style for focused elements on mouse click but show for tab navigation */
  useEffect(() => {
    const handleEvent = (event) => {
      const selector = ["header", "main", "footer"]
      const action =
        event?.type === "mousedown" ? "setAttribute" : "removeAttribute"

      selector.forEach((element) => {
        const el = document.querySelector(element)
        el?.[action]("data-mousedown", "1")
      })
    }

    document.addEventListener("mousedown", handleEvent)
    document.addEventListener("keydown", handleEvent)

    return () => {
      document.removeEventListener("mousedown", handleEvent)
      document.removeEventListener("keydown", handleEvent)
    }
  }, [])

  const hideModal = () => {
    toggleModalVisible(false)
  }

  const showModalWithMessage = (message) => {
    addModalMessage(message)
    toggleModalVisible(true)
  }

  const mobileHandler = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setMageMessages([])
      }
    })
  }, [setMageMessages])

  const setUserLoggedIn = () => {
    userState.logUserIn()
  }

  /* eslint-disable */
  useEffect(() => {
    // custom event to login from adachat
    const container = document.querySelector("#___gatsby")
    container.addEventListener("adaLogUserIn", setUserLoggedIn)
    return () => container.removeEventListener("adaLogUserIn", setUserLoggedIn)
  }, [])
  /* eslint-enable */

  /*
   * basic customer query to see if token is valid
   * set use logged in/logged out based on result
   */
  let gatsbytoken = readCookie("gatsbytoken")
  const { loading, error, data } = useQuery(isCustomerAuthorized, {
    skip: !gatsbytoken || gatsbytoken === "",
  })
  useEffect(() => {
    if (!loading) {
      if (sessionRequestMade && !authRequestMade) {
        setAuthRequestMade(true)
        if (error) {
          console.warn(error)
        }
        if (data) {
          if (data?.customer?.firstname) {
            userState.logUserIn(data.customer)
          }
        } else {
          userState.logUserOut()
          setCookie("gatsbytoken", "", 5)
          verifyRoute()
        }
      }
    }
  }, [
    sessionRequestMade,
    userState,
    authRequestMade,
    setAuthRequestMade,
    loading,
    error,
    data,
  ])

  const verifyRoute = () => {
    const protectedRoutes = path?.startsWith("/account")
    if (protectedRoutes) {
      return navigate("/customer/account/login/")
    }
  }
  /*
   * if they have a token in localstorage
   * check if there is an active session
   * if so, get token and set cookie
   * if not, clear token
   */
  useEffect(() => {
    /**
     * session-login is only called for the gatsby JWT token, if we already have one in cookie
     * and the token is in range of expiration exit out early.
     */
    if (Boolean(readCookie("gatsbytoken"))) {
      setSessionRequestMade(true)
      return
    }

    if (!sessionRequestMade) {
      // check if there is an active session
      fetch(
        `${process.env.GATSBY_MAGENTO_URL}rest/V1/nutrafol-customers/session-login`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((resJson) => {
          if (typeof resJson !== "object") {
            let responseJson = JSON.parse(resJson)
            if (responseJson.success) {
              // set user logged in
              setCookie("gatsbytoken", responseJson.token, 5)
              setSessionRequestMade(true)
            } else {
              if (responseJson.message.length > 0) {
                setMageMessages(responseJson.message)
              }
              setSessionRequestMade(true)
            }
          }
        })
        .catch((error) => {
          console.warn("Fetch error: ", error)
          setSessionRequestMade(true)
        })
    }
  }, [sessionRequestMade, setSessionRequestMade, setMageMessages])
  /*
   * add a check here to redirect logged in users
   * from directly accessing the login/register etc pages
   */
  useEffect(() => {
    if (sessionRequestMade && authRequestMade) {
      if (userState.isLoggedIn) {
        if (ACCOUNT_PAGES.includes(window.location.pathname)) {
          // check if they are in cookie/session loop here and warn in console
          let previous = document.referrer
          if (
            previous ===
            `${process.env.GATSBY_MAGENTO_URL}customer/account/login/`
          ) {
            userState.logUserOut()
            setCookie("gatsbytoken", "", 5)
            console.warn(
              "Session issue detected. Close your current browser window and clear cookies and localstorage."
            )
            // hard refresh
            window.location = `${process.env.GATSBY_MAGENTO_URL}customer/account/login/`
          } else {
            // hard redirect to account index
            window.location = `${process.env.GATSBY_MAGENTO_URL}account/`
          }
        }
      }
    }
  }, [userState, sessionRequestMade, authRequestMade])

  const isCheckout = () => location?.pathname?.indexOf("/checkout/cart") > -1

  const renderHeader = () => {
    if (isHeaderTypeNone) {
      return null
    } else if (isHeaderSimple || isCheckout()) {
      return (
        <>
          <Box className={`header-box-control`}>
            <HeaderSimple
              location={location}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
          <Box className={`header-box-variant`}>
            <HeaderSimpleVariant
              location={location}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
        </>
      )
    } else if (isHeaderReallySimple) {
      return (
        <>
          <Box className={`header-box-control`}>
            <HeaderReallySimple
              location={location}
              withRibbon={isRibbonReallySimple}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
          <Box className={`header-box-variant`}>
            <HeaderReallySimpleVariant
              location={location}
              withRibbon={isRibbonReallySimple}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
        </>
      )
    } else if (isMenTheme) {
      return (
        <>
          <Box className={`header-box-control`}>
            <HeaderWildMen
              location={location}
              withRibbon={isMenRibbon || isRibbonSub}
              PROMO_VARIANT={PROMO_VARIANT}
              contentfulHeaderRibbon={ribbon_info_men}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
          <Box className={`header-box-variant`}>
            <HeaderMen
              location={location}
              withRibbon={true}
              PROMO_VARIANT={PROMO_VARIANT}
              contentfulHeaderRibbon={ribbon_info_men}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
        </>
      )
    } else {
      return (
        <>
          <Box className={`header-box-control`}>
            <HeaderNova
              location={location}
              contentfulHeaderRibbon={ribbon_info_women}
              withRibbon={isRibbonVisible || isRibbonSub}
              PROMO_VARIANT={PROMO_VARIANT}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
          <Box className={`header-box-variant`}>
            <HeaderNovaVariant
              location={location}
              contentfulHeaderRibbon={ribbon_info_women}
              withRibbon={isRibbonVisible || isRibbonSub}
              PROMO_VARIANT={PROMO_VARIANT}
              mobileHandler={mobileHandler}
              siteTitle={siteData.site.siteMetadata.title}
            />
          </Box>
        </>
      )
    }
  }

  const renderSessionMsgs = () => {
    return (
      <SessionMessages
        location={location.pathname}
        mageMessages={mageMessages}
        mainPadding={hasMainTopPadding}
        isLogged={userState.isLoggedIn}
        bgColor={layoutDataBySlug?.header_background_color}
      />
    )
  }

  const showNewsletter = () => {
    return !!location && location.pathname.indexOf("/professionals") === -1
  }

  const includeRecaptchajs = () => {
    const { pathname } = location
    const [lastPathName] = pathname.split("/").filter(Boolean).slice(-1)
    return Boolean(lastPathName) && acceptedCaptchaUrls.includes(lastPathName)
  }

  const includeAcceptjs = () => {
    return (
      !!location &&
      (location.pathname.indexOf("/account/profile/payment/") > -1 ||
        location.pathname.indexOf("/account/profile/shipping/") > -1)
    )
  }

  const includeMapjs = () => {
    return (
      !!location &&
      (location.pathname.indexOf("/account/profile/payment/") > -1 ||
        location.pathname.indexOf("/account/profile/shipping/") > -1)
    )
  }

  const includeRetentionjs = () => {
    return (
      !!location && location.pathname.indexOf("/account/subscriptions/") > -1
    )
  }

  const renderFooter = (layoutData) => {
    if (layoutData?.footer_type === "None") {
      return null
    } else if (
      layoutData?.footer_type === "Really Simple Footer" ||
      isCheckout(location)
    ) {
      return <NovaFooterCart />
    } else if (layoutData?.footer_type === "Simple Footer") {
      if (isMenTheme) {
        return (
          <FooterSimpleWildMen
            showModalWithMessage={showModalWithMessage}
            referenceText={referenceText}
          />
        )
      } else {
        return <NovaFooterLP referenceText={referenceText} />
      }
    } else if (isMenTheme) {
      return (
        <FooterWildMen
          showModalWithMessage={showModalWithMessage}
          referenceText={referenceText}
        />
      )
    } else {
      return (
        <FooterNova
          showModalWithMessage={showModalWithMessage}
          showNewsletter={showNewsletter()}
        />
      )
    }
  }

  const renderScripts = () => {
    return (
      <ExternalScripts
        includeAcceptjs={includeAcceptjs()}
        includeMapjs={includeMapjs()}
        includeRecaptchajs={includeRecaptchajs()}
        includeRetentionjs={includeRetentionjs()}
      />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Consumer>
        {(userState) => (
          <>
            <Helmet>
              {process.env.GATSBY_EVIDON_ID ? (
                <script
                  src={`//c.evidon.com/dg/dg.js`}
                  id="evidonscript"
                  companyid={`${process.env.GATSBY_EVIDON_ID}`}
                  type="text/javascript"
                />
              ) : null}

              {isNoIndexDoFollow(location?.pathname) ? (
                <meta name="robots" content="noindex, dofollow" />
              ) : isNoIndexNoFollow(layoutDataBySlug) ? (
                <meta name="robots" content="noindex, nofollow" />
              ) : null}
            </Helmet>
            {renderHeader()}
            <>
              <Helmet
                bodyAttributes={{
                  class:
                    reformattedPathname === "-" ? "index" : reformattedPathname,
                }}
              />
              <main
                data-mousedown
                className={`
                  body-wrapper
                  ${
                    reformattedPathname === "-" ? "index" : reformattedPathname
                  } 
                  ${hasMainTopPadding ? "main-top-padding" : ""}
                  ${userState.isLoggedIn ? "logged-in" : "not-logged-in"}
                  ${referenceText ? "has-ref-data" : ""}
                  ${layoutDataBySlug?.header_background_color}
                `}
              >
                {(!hasMainTopPadding && !isHeaderTypeNone) ||
                (isSpacerVisible && isRibbonVisible && isHeaderTypeDefault) ? (
                  <div className={`hide-md-up mobile-nav-spacer`}></div>
                ) : null}
                <div className={`mobile-nav-spacer-variant`}></div>
                {renderSessionMsgs()}
                {children}
                {modalVisible && (
                  <Modal
                    component={GenericModalContent}
                    propsObject={{
                      message: modalMessage,
                      closeButtonText: "OK",
                    }}
                    customZIndex={31}
                    hideModal={hideModal}
                  />
                )}
              </main>
            </>
            {renderFooter(layoutDataBySlug)}
            {renderScripts()}
          </>
        )}
      </UserContext.Consumer>
    </ThemeProvider>
  )
}

export default Layout
