import React, { useState, useEffect, useRef, useContext } from "react"
import classNames from "classnames"
import { Flex, Box } from "@rebass/grid/emotion"

import popupHelper from "@nutrafol/popup-helper"
import { data } from "./data"
import { ButtonWildMen } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWildMen"

import Navigation from "./navigation"
import MultiLevelNavigation from "./multilevelnavigation"
import Logo from "./logo"
import HeaderIcons from "./headericons"
import Ribbon from "./ribbon"
import HeaderAtc from "./header-atc"
import { isProductPathMen } from "../../../../utils/general"
import UserContext from "../../../../context/UserContext"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import AccountIcon from "./accounticon"
import NavSwitch from "./navswitch"

import {
  HeaderWrap,
  HeaderContainer,
  ContentsContainer,
  NavVar1,
  AboveHeaderWrap,
  SiteSwitchHolder,
} from "./header.styled"

import SiteSwitch from "./siteswitch"

const ctaText = "Take the Quiz"
const HeaderMen = (props) => {
  const isMouseInHeader = useRef(false)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [secondLevelIsOpen, setSecondLevel] = useState(false)
  const [activeItemIndex, setActiveItemIndex] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const userState = useContext(UserContext)
  const pathname = props?.location?.pathname
  /*
   * https://nutrafol.atlassian.net/browse/NT2-22233
   * We would like to add the ability to SiteSwitch to the Skin page from the Men's navigation.
   * We would like to Add ribbons for both site navigations.
   *
   * const PROMO_VARIANT = props.PROMO_VARIANT
   */
  const withRibbon = props.withRibbon
  let PROMO_VARIANT = "serum"
  const contentfulHeaderRibbon = props.contentfulHeaderRibbon
  let isProductPage = true
  let isAccountPage = false
  let isQuizLp = false
  let showCart = true
  let isTarget = false

  if (pathname) {
    showCart = pathname.indexOf("/checkout") === -1
    isAccountPage = pathname.indexOf("account/") > -1
    isQuizLp = pathname.indexOf("hair-wellness-quiz-intro") > -1
    //isTarget = pathname === contentfulHeaderRibbon?.ribbonLink
    isTarget = pathname === `/men/products/thinning-hair-supplements-and-serum/`
  }

  //NT2-22394 Ribbon | Ribbon updated for PDPs
  if (isTarget) {
    PROMO_VARIANT = `ribbon_products`
  }

  const isZeLoaded = () => !!(window.zE && typeof window.zE.show === "function")
  const isAdaLoaded = () => !!window.adaEmbed
  const isAttentiveLoaded = () => !!window.attentive_overlay

  const toggleMobileNav = (isOpen = false) => {
    segmentEvent("Hamburger Navigation Menu Clicked", {
      module_location: "navigation",
      path: "/",
    })

    if (isAttentiveLoaded()) {
      try {
        window.attentive_overlay.style.zIndex = isOpen ? "10" : "2147483647"
      } catch (error) {
        console.error("attentive_overlay  zIndex failed ", error)
      }
    }
    if (!isOpen) {
      setNavIsOpen(false)
      toggleScroll(true)
      deactivateSecondLevelMenu()
      if (isZeLoaded()) {
        window.zE.show()
      } else if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "block"
        }
      }
    } else {
      setNavIsOpen(true)
      toggleScroll(false)
      if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "none"
        }
      } else if (isZeLoaded()) {
        window.zE.hide()
      } else {
        // if menu is tapped within 3 seconds of page load - wait for zE and hide it
        let zeInterval = setInterval(() => {
          if (isZeLoaded()) {
            window.zE.hide()
            clearInterval(zeInterval)
          }
        }, 250)
      }
    }
  }

  const toggleScroll = (exists = false) => {
    const bodyEl =
      typeof window !== "undefined" ? document.querySelector("body") : null

    if (bodyEl) {
      if (exists === true) {
        bodyEl.style.removeProperty("overflow")
        bodyEl.style.removeProperty("position")
        bodyEl.style.removeProperty("top")
        bodyEl.style.removeProperty("width")
        window.scrollTo(0, scrollPosition)
      } else {
        const scrolledOffset = window.pageYOffset
        setScrollPosition(scrolledOffset)
        bodyEl.style.overflow = "hidden"
        bodyEl.style.position = "fixed"
        bodyEl.style.top = `-${scrolledOffset}px`
        bodyEl.style.width = "100%"
      }
    }
  }

  if (typeof window !== "undefined") {
    // close menu on resize > 1024
    const handleResize = () => {
      const navEl = document.querySelector(".nav-open")
      if (navEl && window.innerWidth >= 1024) {
        toggleMobileNav(false)
      }
    }
    window.addEventListener("resize", handleResize)
  }

  const handleNavLogoExitClick = () => {
    toggleScroll(true)
    toggleMobileNav(false)
    userState.setAtcData(null)
    userState.setActiveOption(0)
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination: "/men/",
      type: "link",
    })
    setTimeout(() => {
      try {
        document.body.classList.remove("stickyatc")
      } catch (e) {
        console.warn("stickyatc error")
      }
    }, 200)
  }

  const deactivateSecondLevelMenu = () => {
    setActiveItemIndex(null)
    setMenuIsOpen(false)
    setSecondLevel(false)
  }

  const handleScrollClass = () => {
    const classList = document.body.classList
    const val = document.documentElement.scrollTop
    const pageScrolled = classList.contains("pagescrolled")
    if (!isMouseInHeader.current) {
      if (val > 85) {
        if (!pageScrolled) {
          classList.add("pagescrolled")
        }
      } else {
        if (pageScrolled) {
          classList.remove("pagescrolled")
        }
      }
    }
  }

  useEffect(() => {
    let rect = { y: 0 }
    let gap = popupHelper.isMobile() ? 0 : 40
    const handleScroll = () => {
      handleScrollClass()

      // PDP pages are using setAtcData() via context and setting .stickyatc class
      if (isProductPathMen(window.location.pathname)) {
        const classList = document.body.classList
        const pageScrolledAtc = classList.contains("stickyatc")
        document.querySelectorAll(".buy-once").forEach((ela) => {
          if (ela && ela.offsetParent && ela.offsetTop) {
            rect = ela.getBoundingClientRect()
          }
        })
        if (userState.atcData && rect.y - gap < 0) {
          if (!pageScrolledAtc) {
            classList.add("stickyatc")
          }
        } else {
          if (pageScrolledAtc) {
            sessionStorage.removeItem("stickyatcheaderIsClicked")
            classList.remove("stickyatc")
          }
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [userState.atcData])

  const onLeave = () => {
    isMouseInHeader.current = false
    handleScrollClass()
  }

  const onEnter = () => {
    isMouseInHeader.current = true
  }

  const onClick = () => {
    segmentEvent("Marketing CTA Clicked", {
      destination: "/men/quiz/",
      module_location: "navigation",
      text: ctaText,
      type: "link",
    })
    setTimeout(() => {
      window.location = process.env.GATSBY_MAGENTO_URL + "men/quiz/"
    }, 500)
  }
  const TakeQuiz = () => {
    if (userState.isLoggedIn) {
      return <AccountIcon />
    }
    return (
      <ButtonWildMen
        textLink
        label={ctaText}
        onClick={onClick}
        className={`header-quiz-link`}
      />
    )
  }
  return (
    <UserContext.Consumer>
      {(state) => (
        <>
          <HeaderWrap
            data-mousedown
            className={classNames("header-wrap header-wrap-variant", {
              "nav-open": navIsOpen,
              "second-level-active": secondLevelIsOpen,
              "has-ribbon": withRibbon,
              "product-page": isProductPage,
            })}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onTouchEnd={() =>
              setTimeout(() => {
                onLeave()
              })
            }
            onClick={onLeave}
          >
            {!isAccountPage ? (
              withRibbon ? (
                <AboveHeaderWrap flexWrap="wrap" className={`ribbon-container`}>
                  <SiteSwitchHolder width={[1, 1, 300]} className={``}>
                    <SiteSwitch />
                  </SiteSwitchHolder>
                  <Box width={[1, 1, "calc(100% - 300px)"]}>
                    <Ribbon
                      contentfulHeaderRibbon={contentfulHeaderRibbon}
                      isMouseInHeader={isMouseInHeader}
                      PROMO_VARIANT={PROMO_VARIANT}
                    />
                  </Box>
                </AboveHeaderWrap>
              ) : (
                <Box className={`no-ribbon-switch`}>
                  <SiteSwitchHolder width={[1]}>
                    <SiteSwitch className={`rounded-tr-[4px]`} />
                  </SiteSwitchHolder>
                </Box>
              )
            ) : null}

            <HeaderContainer className="container container-mens">
              <div className="stickyatcheader">
                <HeaderAtc state={state} />
              </div>
              <ContentsContainer className="nonstickyheader wrap">
                <span className="hide-lg-up">
                  <NavVar1 flexWrap="wrap" alignItems="center" width={[1]}>
                    <Box width={[1 / 3]}>
                      <NavSwitch type="women" copy="Women" />
                    </Box>
                    <Box width={[1 / 3]}>
                      <NavSwitch type="skin" copy="Skin" />
                    </Box>
                    <Box width={[1 / 3]} className="takequiz">
                      <TakeQuiz />
                    </Box>
                  </NavVar1>
                </span>
                <div
                  className={classNames("flex flex-nowrap items-center", {
                    relative: true,
                  })}
                >
                  <Box
                    width={[67, 67, 67, 0]}
                    className="toggler-wrap hide-lg-up"
                    style={{ height: "54px" }}
                  >
                    <HeaderIcons
                      navIsOpen={navIsOpen}
                      showCart={false}
                      isProductPage={isProductPage}
                      isAccountPage={isAccountPage}
                      isQuizLp={isQuizLp}
                      toggleMobileNav={() => toggleMobileNav(!navIsOpen)}
                    />
                  </Box>
                  <Box
                    width={["calc(30% - 70px)", null, null, "calc(50% - 92px)"]}
                    className="left-wrap"
                  >
                    <Navigation isProductPage={isProductPage} />
                  </Box>
                  <Box
                    width={[4 / 10, 4 / 10, 4 / 10, 183]}
                    className="center-wrap"
                  >
                    <Logo handleNavLogoExitClick={handleNavLogoExitClick} />
                  </Box>
                  <Box
                    width={[3 / 10, 3 / 10, 3 / 10, "calc(50% - 92px)"]}
                    className="right-wrap"
                  >
                    <Flex
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <HeaderIcons
                        navIsOpen={navIsOpen}
                        showCart={showCart}
                        classNameBurger={`hidden`}
                        isProductPage={isProductPage}
                        isAccountPage={isAccountPage}
                        isQuizLp={isQuizLp}
                        toggleMobileNav={() => toggleMobileNav(!navIsOpen)}
                      />
                    </Flex>
                  </Box>
                </div>
              </ContentsContainer>
            </HeaderContainer>
            <MultiLevelNavigation
              data={data}
              isProductPage={isProductPage}
              activeItem={activeItemIndex}
              setActiveItem={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              toggleNav={setNavIsOpen}
              navIsOpen={navIsOpen}
              toggleSecondLevel={setSecondLevel}
              secondLevelIsOpen={secondLevelIsOpen}
              menuIsOpen={menuIsOpen}
              toggleMenu={setMenuIsOpen}
              toggleScroll={toggleScroll}
              deactivateSecondLevelMenu={deactivateSecondLevelMenu}
            />
          </HeaderWrap>
        </>
      )}
    </UserContext.Consumer>
  )
}

export default HeaderMen
