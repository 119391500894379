import React, { useState, useContext } from "react"
import { navigate, Link } from "gatsby"
import styled from "@emotion/styled"
import classNames from "classnames"
import { Box, Flex } from "@rebass/grid/emotion"

import UserContext from "../../../../context/UserContext"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import { ButtonNova } from "@nutrafol/nutrafol-ui-kit/dist/ButtonNova"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import PlusIcon from "../../../../assets/icons/nova/plus-grey-var.svg"
import MinusIcon from "../../../../assets/icons/nova/minus-grey.svg"
import UserGrey from "../../../../assets/icons/nova/user-grey.svg"

import BottomNavigationMobile from "./bottomnavigationmobile"

const Nav = styled.nav`
  display: none;
  position: fixed;
  background: ${theme.color.white};
  width: 100%;
  height: calc(100% - 96px);
  left: 0;
  bottom: 0;
  padding: 0;
  z-index: 12;
  overflow-y: scroll;
  top: 96px;
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 0.2);

  @media (max-width: 360px) {
    button.ButtonNova .CTA-20-Dia-Normal,
    button.ButtonNova .account-label {
      font-size: 12px !important;
    }
  }
  @media (min-width: 1024px) {
    box-shadow: 0px 11px 15px 0px rgba(0, 87, 82, 0.08);
    box-shadow: 0px 9px 46px 0px rgba(0, 87, 82, 0.06);
    box-shadow: 0px 24px 38px 0px rgba(0, 87, 82, 0.06);
    left: 8px;
    top: 128px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: none;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
  }
`

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    padding-top: ${theme.spacing.small};
    height: 100%;
  }

  &.navIsOpen {
    animation: closeSubMenu 0.25s;
    -moz-animation: closeSubMenu 0.25s;
    -webkit-animation: closeSubMenu 0.25s;
  }

  &.menuIsOpen {
    left: -100%;
    animation: openSubMenu 0.25s;
    -moz-animation: openSubMenu 0.25s;
    -webkit-animation: openSubMenu 0.25s;
  }
`

const Products = styled(Box)`
  background-color: ${theme.wild.color.nova.base.solid[20]};
  button:not(.textLink) {
    width: 100%;
  }
`

const AccordionButton = styled.button`
  display: block;
  width: 100%;
  padding: 16px 0;

  svg {
    transition: transform ease-in-out 0.15s;
    width: 24px !important;
    height: 24px !important;
  }

  &.active svg {
    transform: rotate(180deg);
  }
`

const AccordionBody = styled(Box)`
  .products {
    background: #e8faf7;
    padding: 24px;
  }
  button {
    width: 100%;
  }

  .sub-item-link {
    padding: 12px 0;

    &.services {
      padding: 8px 0;
    }
  }
`

const AccordionBox = styled(Box)`
  border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  margin: 0 24px;
`

const MenuButton = styled.button`
  display: block;
  width: 100vw; /* Fallback for older browsers */
  width: -webkit-fill-available; /* Chrome/Safari (WebKit) */
  width: -moz-available; /* Firefox */
  padding: 16px 0;
  text-align: left;
  margin: 0 24px;

  &.Results {
    border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  }
`

const FixedBottomNav = styled(Flex)`
  position: fixed;
  background: ${theme.color.white};
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow:
    0px -10px 15px 0px rgba(0, 0, 0, 0.04),
    0px -6px 10px 0px rgba(0, 0, 0, 0.02),
    0px -2px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 1024px) {
    left: 8px;
    right: 8px;
  }

  button {
    width: 100%;
    padding: 13px 0 !important;
    height: 48px !important;

    .account-label {
      font-size: 14px !important;
    }
  }
`

const label = "Shop All Products"

// Accordion Component
const Accordion = ({ title, open = false, children }) => {
  const [isOpen, setIsOpen] = useState(open)

  const toggleAccordion = () => {
    if (!isOpen) {
      segmentEvent("Navigation Expanded", {
        module_location: "navigation",
        text: title,
      })
    }
    setIsOpen(!isOpen)
  }

  return (
    <AccordionBox>
      <AccordionButton
        className={isOpen ? "active" : ""}
        onClick={toggleAccordion}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <TextElementNova
              text={title}
              className="Body-30-Dia-Normal text-nova-base-black"
            />
          </Box>
          <Box>{isOpen ? <MinusIcon /> : <PlusIcon />}</Box>
        </Flex>
      </AccordionButton>
      {isOpen ? <Box mb={16}>{children}</Box> : null}
    </AccordionBox>
  )
}

const AccordionNavigation = ({
  data,
  toggleNav,
  navIsOpen,
  menuIsOpen,
}) => {
  const userState = useContext(UserContext)

  const trackClicked = (text, destination, segmentName) => {
    segmentEvent(segmentName, {
      module_location: "navigation",
      type: "button",
      text,
      destination,
    })
  }

  const handleLinkClick = (
    text,
    destination,
    donav = false,
    segmentName = "Navigation Clicked"
  ) => {
    setTimeout(toggleNav(false), 50)
    trackClicked(text, destination, segmentName)
    if (destination !== "#" && donav) {
      navigate(destination)
    }
  }

  const handleQuizClick = (label) => {
    segmentEvent("Marketing CTA Clicked", {
      destination: "/quiz/",
      module_location: "navigation",
      text: label,
      type: "button",
    })
    setTimeout(() => {
      window.location = process.env.GATSBY_MAGENTO_URL + "quiz/"
    }, 500)
  }

  const generateAccountButton = (isLoggedIn) => {
    const dest = isLoggedIn ? "/account/" : "/customer/account/login/"
    const label = isLoggedIn ? "My Account" : "Log In"

    return (
      <ButtonNova
        level={`Secondary`}
        width={`100%`}
        onClick={() =>
          handleLinkClick(label, dest, true, "Marketing CTA Clicked")
        }
      >
        <Flex alignItems="center">
          <Box className={`account-label`}>{label}</Box>
          <Box width={24} height={24} ml={12}>
            <UserGrey width="100%" height="100%" />
          </Box>
        </Flex>
      </ButtonNova>
    )
  }

  return (
    <Nav className="mobile-navigation">
      <MenuWrapper
        className={classNames("menu-wrapper", {
          menuIsOpen: menuIsOpen,
          navIsOpen: navIsOpen,
        })}
      >
        {data.map((section, index) => (
          <div key={section.name}>
            {section.type === "hgn" ? (
              <Products py={24} px={24} m={24}>
                <Box>
                  <TextElementNova
                    text={section.title}
                    className="Subtitle-10-Dia-Normal text-nova-base-black mb-6"
                  />
                </Box>
                <Box>
                  <Box className={section.name}>
                    {section.children.map((subitem, idx) => (
                      <Box
                        key={`${section.name}-${subitem.title}`}
                        mb={section.children.length !== idx + 1 ? 16 : 0}
                      >
                        {subitem.type === "link" ? (
                          <Link
                            onClick={() =>
                              handleLinkClick(subitem.title, subitem.url)
                            }
                            to={subitem.url}
                            aria-label={`Open ${subitem.name} page`}
                            id={`SubItemLink-${index}-${idx}`}
                            className="block"
                          >
                            <TextElementNova
                              type="span"
                              text={subitem.title}
                              className={`${
                                section.name === "products"
                                  ? "Body-20-Dia-Strong"
                                  : "Body-20-Dia-Strong"
                              } text-nova-brand-primary-100`}
                            />
                            {subitem.subtitle ? (
                              <TextElementNova
                                type="span"
                                text={subitem.subtitle}
                                className={`Body-10-Dia-Normal ${
                                  section.name === "products"
                                    ? "text-nova-base-black"
                                    : "text-nova-base-alt-alt"
                                }`}
                              />
                            ) : null}
                          </Link>
                        ) : null}
                        {subitem.type === "button" ? (
                          <Box pt={["8px"]}>
                            <ButtonNova
                              icon={`Right`}
                              width={`100%`}
                              label={subitem.label}
                              className={`header-quiz-link`}
                              onClick={() => handleQuizClick(subitem.label)}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Products>
            ) : null}
            {section.type === "accordion" ? (
              <Accordion title={section.title} open={section.open}>
                <AccordionBody px={16}>
                  <Box className={section.name}>
                    {section.children.map((subitem, idx) => (
                      <Box key={idx}>
                        {subitem.type === "link" ? (
                          <Link
                            className={`sub-item-link block ${section.name}`}
                            onClick={() =>
                              handleLinkClick(subitem.title, subitem.url)
                            }
                            to={subitem.url}
                            aria-label={`Open ${subitem.title} page`}
                            id={`SubItemLink-${index}-${idx}`}
                          >
                            <TextElementNova
                              type="span"
                              text={subitem.title}
                              className={`Body-20-Dia-Normal`}
                            />
                            {subitem.subtitle ? (
                              <TextElementNova
                                type="span"
                                text={subitem.subtitle}
                                className={`Body-10-Dia-Normal pt-1 text-nova-transparency-black-60`}
                              />
                            ) : null}
                          </Link>
                        ) : null}
                        {subitem.type === "button" ? (
                          <ButtonNova
                            width={`100%`}
                            label={subitem.label}
                            onClick={() => handleQuizClick(subitem.label)}
                          />
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </AccordionBody>
              </Accordion>
            ) : null}
            {section.type === "link" ? (
              <MenuButton
                className={section.title}
                onClick={() =>
                  handleLinkClick(section.title, section.url, true)
                }
              >
                <TextElementNova
                  type="span"
                  text={section.title}
                  className="Body-30-Dia-Normal text-nova-base-black"
                />
              </MenuButton>
            ) : null}
          </div>
        ))}
        <Box pt={16} pb={72}>
          <BottomNavigationMobile toggleNav={toggleNav} />
        </Box>
      </MenuWrapper>
      <FixedBottomNav height={64} px={16} py={8}>
        <Box width={1 / 2} pr="4px">
          {generateAccountButton(userState.isLoggedIn)}
        </Box>
        <Box width={1 / 2} pl="4px">
          <ButtonNova
            level={`Secondary`}
            icon={`Right`}
            width={`100%`}
            label={label}
            onClick={() => handleLinkClick(label, "/products/", true)}
          />
        </Box>
      </FixedBottomNav>
    </Nav>
  )
}

export default AccordionNavigation
