import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"

import NotificationMessages from "../session/notificationmessages"
import { notificationActionObject } from "../../utils/notificationlinkactions"
import { notificationBarViewed } from "../../utils/segmentfunctions"
import { useIsFirstRender } from "../../hooks/useIsFirstRender"

const TopSection = styled(Flex)`
  justify-content: space-between;
`
const NotificationCard = styled.div`
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 9px 11px;

  border: 1px solid #008078;
  padding: 9px 11px;

  &.error {
    border: 1px solid red;
  }
  &.warning {
    border: 1px solid orange;
  }
  &.beige {
    border: 1px solid #008078;
    border-radius: 8px;
  }
  &.full-width {
    width: 100%;
  }

  &:not(.with-margin) {
    margin-bottom: 32px;
  }

  &.with-margin {
    margin: 32px 0 -16px;
    @media (min-width: 1024px) {
      margin: 40px 0 -8px;
    }
  }
`

const NotificationBar = (props) => {
  const [showNotification] = useState(true)

  const {
    message,
    classes = "",
    customActionText,
    customActionLink,
    customActionFunc,
    columnSmall,
  } = props

  let _classes = classes || ""

  console.log("message", message)

  if (message.type) {
    _classes += " " + message.type
  }

  const dataObj = notificationActionObject[message.actionType]

  const { actionText, actionLink, actionFunc } = dataObj || {}

  const notification_title = customActionText || actionText

  const isFirstRender = useIsFirstRender()
  useEffect(() => {
    if (isFirstRender) {
      notificationBarViewed(notification_title, message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification_title, message])

  /*
   * const actionLinkClick = () => {
   *   notificationBarClicked(notification_title, page_title, message)
   * }
   */

  return (
    <>
      {showNotification ? (
        <NotificationCard className={`c-NotificationBar ${_classes}`}>
          <TopSection
            flexDirection={["column", "column", "row"]}
            flexWrap={["wrap"]}
          >
            <Box width={[1, 1, 1, 1]}>
              <NotificationMessages
                message={message}
                actionText={customActionText ? customActionText : actionText}
                actionLink={customActionLink ? customActionLink : actionLink}
                actionFunc={customActionFunc ? customActionFunc : actionFunc}
                columnSmall={columnSmall}
              />
            </Box>
          </TopSection>
        </NotificationCard>
      ) : null}
    </>
  )
}

export default NotificationBar
